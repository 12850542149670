import React, { useEffect, useState } from 'react';
import DIYSideNav from './DIYSideNav';
import { Breadcrumb, Button, Form, FormCheck, Modal } from 'react-bootstrap';
import { imagePath, ErrorNotify, successNotify } from '../../CommonLogic';
import { client } from '../../services/apiService';
import { permissionImages } from '../../Constant';
import * as AuthSelector from '../../store/auth/selectors';
import { validateToken } from '../../store/auth/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import ObsStructure from '../Audit/ObsStructure';

const RolePermissions = () => {
    const [role, setRole] = useState('');
    const [permissionList, setPermissionList] = useState([]);
    const [showPermissionCard, setShowPermissionCard] = useState(true);
    const [designationAndResource, setDesignationAndResource] = useState([]);
    const [updatedPermissionList, setUpdatedPermissionList] = useState([]);
    const [show4, setShow4] = useState(false);
    const [accessPermissionList, setAccessPermissionList] = useState([]);
    const [asPermission, setAsPermission] = useState("");
    const [designationName, setDesignationName] = useState("");
    const organisationId = '{{org-id}}';
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const permissionOrder = [
        'Integration Setup',
        'Filter Setup',
        'Audit Sheet Setup',
        'Perform Audit',
        'View Audits',
        'Knowledge Management',
        'Organization Setup',
        'User Onboarding',
        'Quality Function Dashboard',
        'Work Allocation Dashboard',
        'Access Management',
        'Billing',
        'Organisation Profile',
        'Metabase Analytics',
        'User Management'
    ];
    useEffect(() => {
        if (localStorage.getItem('userToken')) {
            dispatch(validateToken());
        } else {
            navigate('/login');
        }
    }, [orgStrId]);
    // 1
    useEffect(() => {
        fetchDesignationWithAccessResource();
    }, []);

    const updateRoleOption = event => {
        const selectedValue = event.target.value;
        const accessPermissionList = [];
        setPermissionList(
            sortPermissions(
                designationAndResource.filter(e => e.id == selectedValue)['0']
                    ?.Access_Permissions
            )
        );
        designationAndResource
            .filter(e => e.id == selectedValue)
            ['0']?.Access_Permissions?.map(e => {
                if (e.checked == true) accessPermissionList.push(e.id);
            });
        setUpdatedPermissionList(accessPermissionList);
        setRole(selectedValue);
    };
    const handleShow4 = () => setShow4(true);

    // for permission card
    const handleClose = () => {
        setShowPermissionCard(false);
    };

    // add designation model
    const handleCloseSingleUserModal = () => {
        setShow4(false);
    };

    async function fetchDesignationWithAccessResource() {
        const response = await client.get(
            `/permission-groups/getOrgRoleWithResource/${organisationId}`
        );
        if (response.success) {
            const designation = (response?.data || []).filter(
                item => !item.Display_Name.startsWith('Sys.')
            ); // for excluding designation with name Sys.
            setDesignationAndResource(designation);
            setRole(designation[0]?.id);
            const accessPermissionList = [];
            setPermissionList(
                sortPermissions(
                    designation.filter(e => e.id == designation[0]?.id)['0']
                        ?.Access_Permissions
                )
            );
            designation
                .filter(e => e.id == designation[0]?.id)
                ['0']?.Access_Permissions?.map(e => {
                    if (e.checked == true) accessPermissionList.push(e.id);
                });
            setUpdatedPermissionList(accessPermissionList);
        }
    }

    function handleResourceSelection(id) {
        setPermissionList(prevPermissions =>
            prevPermissions.map(permission =>
                permission.id == id
                    ? { ...permission, checked: !permission.checked }
                    : permission
            )
        );
        let numbers = toggleNumber(updatedPermissionList, id); // Removes permission id
        setUpdatedPermissionList(numbers);
    }

    function toggleNumber(arr, num) {
        const index = arr.indexOf(num);

        if (index === -1) {
            // Number is not in the array, so add it
            arr.push(num);
        } else {
            // Number is in the array, so remove it
            arr.splice(index, 1);
        }

        return arr;
    }

    async function handleSubmit() {
        if (role == '') {
            ErrorNotify('Please select a designation');
            return;
        }
        try {
            const response = await client.put(`/permission-groups/${role}`, {
                data: { Access_Permissions: updatedPermissionList },
            });
            if (response.success) {
                successNotify('Permissions have been successfully updated.');
                fetchDesignationWithAccessResource();
            } else {
                ErrorNotify('Failed to update permissions.');
            }
        } catch (error) {}
    }

    function handleDiscard() {
        const accessPermissionList = [];
        setPermissionList(
            sortPermissions(
                designationAndResource.filter(e => e.id == role)['0']
                    ?.Access_Permissions
            )
        );
        designationAndResource
            .filter(e => e.id == role)
            ['0']?.Access_Permissions?.map(e => {
                if (e.checked == true) accessPermissionList.push(e.id);
            });
        setUpdatedPermissionList(accessPermissionList);
    }
    const sortPermissions = permissions => {
        return permissions.sort((a, b) => {
            const indexA = permissionOrder.indexOf(a.permission);
            const indexB = permissionOrder.indexOf(b.permission);
            return indexA - indexB;
        });
    };

    const handleSetAsPermission = event => {
        if (event.target.value !== 'Select') {
            setAsPermission(JSON.parse(event.target.value));
        } else {
            setAsPermission('');
        }
    };

    const handleSubmitPermisson = async () => {
        const payload = {
            data: {
                Role_Name: designationName,
                organisation: {
                    connect: [
                        {
                            id: organisationId,
                        },
                    ],
                },
                Access_Permissions: asPermission?.Access_Permissions?.filter(
                    permission => permission?.checked
                )?.map(permission => permission?.id),
            },
        };
        const response = await client.post('/permission-groups', payload);
        if (response.success) {
            successNotify('Designation created successfully.');
            fetchDesignationWithAccessResource();
        } else {
            ErrorNotify('Failed to cretae Designation.');
        }
        handleCloseSingleUserModal();
    };
    return (
        <>
            <div className='page-wrapper no_overflow_x page_loader--'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-12 sidebar_right_nav sidebar_right_style'>
                            <div className='sidebar-right'>
                                <p>Organization Setup</p>
                                <ul className='page_nav'>
                                    <DIYSideNav tab='org-setup' />
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-12 col-md-8 col-sm-12 content_wrap_main content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='breadcrumb_box'>
                                            <Breadcrumb>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    href='/'>
                                                    <img
                                                        src={imagePath(
                                                            '/images/home_icon_pagig.svg'
                                                        )}
                                                    />
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    active
                                                    aria-current='page'>
                                                    Setup / Organization Setup
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>
                                    </div>
                                </div>
                                <div className='two_column_title page_title_box'>
                                    <h3 className='page_title'>
                                        <span className='pe-3'>
                                            <button className='btn btn_transparent'>
                                                <img
                                                    onClick={() => {
                                                        navigate(
                                                            `/o/${orgStrId}/setup/user-management`
                                                        );
                                                    }}
                                                    src='/images/back_arrow.svg'
                                                    alt='back button'
                                                />
                                            </button>
                                        </span>{' '}
                                        Role Permission
                                    </h3>

                                    <button
                                        className='btn bordered_btn'
                                        onClick={async () => {
                                            handleShow4();
                                            if (
                                                accessPermissionList?.length ===
                                                0
                                            ) {
                                                const response =
                                                    await client.get(
                                                        `/access-permissions/getUniquePermission`
                                                    );
                                                setAccessPermissionList(
                                                    response?.data
                                                );
                                            }
                                            // handleGetDataToCreateUser();
                                        }}>
                                        Add Designation
                                    </button>
                                </div>
                                <div></div>
                                <form className='form_style_comman'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='row'>
                                                <div className='col-lg-3 form-group'>
                                                    <label htmlFor='Designation'>
                                                        Designation:
                                                    </label>
                                                    <select
                                                        className='form-control'
                                                        defaultValue={
                                                            designationAndResource[0]
                                                                ?.id
                                                        }
                                                        name={
                                                            designationAndResource[0]
                                                                ?.Display_Name
                                                        }
                                                        onChange={
                                                            updateRoleOption
                                                        }>
                                                        {designationAndResource?.map(
                                                            (e, i) => {
                                                                return (
                                                                    <option
                                                                        key={i}
                                                                        value={
                                                                            e.id
                                                                        }>
                                                                        {
                                                                            e.Display_Name
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-lg-3 form-group'>
                                                    <label htmlFor='ObsStructure'>
                                                        OBS:
                                                    </label>
                                                    <ObsStructure />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </form>

                                <div className='mt-'>
                                    <div className='row'>
                                        {false && showPermissionCard && (
                                            <div className='col-lg-4 permission_card_box'>
                                                <div className='permission_card card p-3'>
                                                    <div className='d-flex'>
                                                        <div className='permission_card_media me-3'>
                                                            <img
                                                                className='w-100 rounded-circle'
                                                                src='/images/user_QA.jpg'
                                                                alt='Generic placeholder image'
                                                            />
                                                        </div>
                                                        <div className='media-body'>
                                                            <h6 className='h6 mt-0 mb-1'>
                                                                Olivia Rhye |
                                                                Agent
                                                            </h6>
                                                            <p className='text_grey_color mb-0'>
                                                                Updated: 50 min
                                                                ago
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className='permission_close'
                                                        onClick={handleClose} // Call handleClose when clicked
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}>
                                                        X
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='table_style_comman role_permission_tbl'>
                                        <div className='table-responsive'>
                                            <table class='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Resources</th>
                                                        <th className='text-center'>
                                                            Access
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {role !== '' &&
                                                        permissionList?.map(
                                                            event => {
                                                                return (
                                                                    <tr
                                                                        key={`DesignationResource ${event.id}`}
                                                                        className='role_parent_td'>
                                                                        <td className='role_menu_icon'>
                                                                            <i className='me-2'>
                                                                                <img
                                                                                    src={
                                                                                        permissionImages[
                                                                                            event
                                                                                                .permission
                                                                                        ]
                                                                                            ? permissionImages[
                                                                                                  event
                                                                                                      .permission
                                                                                              ]
                                                                                            : `/images/menu_icon_dashboard.svg`
                                                                                    }
                                                                                />
                                                                            </i>
                                                                            {
                                                                                event.permission
                                                                            }
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <div className='checkbox_custom checkbox_small'>
                                                                                <Form.Check className='form-check-inline m-0'>
                                                                                    <FormCheck.Input
                                                                                        type='checkbox'
                                                                                        className='form-check-input'
                                                                                        checked={
                                                                                            event.checked
                                                                                        }
                                                                                        onChange={() =>
                                                                                            handleResourceSelection(
                                                                                                event.id
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Form.Check>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='text-end mt-1'>
                                        <button
                                            onClick={handleDiscard}
                                            className='btn bordered_btn me-3'>
                                            Discard
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            className='btn comman_btn'>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <Modal
                show={show4}
                onHide={() => {
                    handleCloseSingleUserModal();
                }}
                backdrop='static'
                keyboard={false}
                className='deesignation_modal'>
                <Modal.Header>
                    <h4 className='text-primary'>Add Designation</h4>
                </Modal.Header>
                <Modal.Body>
                    <form className='form_style_comman'>
                        <div className='row'>
                            <div className='col-lg-12 form-group'>
                                <label>
                                    Designation Name
                                    <sup class='sup_manatory'>*</sup>
                                </label>
                                <input type='text' className='form-control' onChange={(e)=>setDesignationName(e.target.value)}/>
                            </div>
                            <div className='col-lg-12 form-group'>
                                <label>Permissions same as</label>
                                <select className='form-control' onChange={handleSetAsPermission}>
                                <option value="Select">Select</option>
                                    {designationAndResource?.map(el => (
                                        <option key={el?.id} value={JSON.stringify(el)}>{el?.Display_Name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='text-end mt-3 col-12'>
                                <Button
                                    className='btn bordered_btn me-3'
                                    onClick={() => {
                                        handleCloseSingleUserModal();
                                    }}>
                                    Cancel
                                </Button>
                                <Button disabled={!asPermission || !designationName} variant='primary' type='button' onClick={handleSubmitPermisson}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RolePermissions;
