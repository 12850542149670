import React, { useEffect, useState, useRef } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { ErrorNotify, imagePath, infoNotify } from '../../CommonLogic'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as AuthSelector from '../../store/auth/selectors';
import { useNavigate } from 'react-router-dom';
import { client } from '../../services/apiService'

const ManageBilling = () => {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const organisation = useSelector(AuthSelector.organization);
    const navigate = useNavigate();

    // States for organizations
    const [organizationOriginalList, setOrganizationOriginalList] = useState([]); // Original organization list
    const [organizationFilteredList, setOrganizationFilteredList] = useState([]); // Filtered organization list

    // States for departments
    const [departmentOriginalList, setDepartmentOriginalList] = useState({}); // Original department list
    const [departmentFilteredList, setDepartmentFilteredList] = useState({}); // Filtered department list

    const [searchString, setSearchString] = useState(''); // Search input
    const isOrganization = useRef(true); // Flag to determine search type (organization or department)

    useEffect(() => {
        if (orgStrId) fetchOrganisationList(); // Fetch the organisation data initially
    }, [orgStrId])

    // Fetch all organizations and update both original and filtered lists
    async function fetchOrganisationList() {
        let urlChoice = organisation?.organisationName == 'Omind' ? '/organisations?populate=Departments' : `/organisations?filters[Org_str_id][$eq]=${orgStrId}&populate=Departments`;
        try {
            const response = await client.get(urlChoice);
            if (response.success) {
                setOrganizationOriginalList(response.data); // Store original organization data
                setOrganizationFilteredList(response.data); // Initialize filtered list
            }
        } catch (error) {
            ErrorNotify('Error fetching organizations');
        }
    }

    // Fetch department list based on a department ID (drill-down)
    async function fetchDepartmentList(departmentId) {
        if (!departmentId) {
            infoNotify("Invalid department ID");
            return;
        }
        isOrganization.current = false;
        try {
            const response = await client.get(`/departments/${departmentId}?populate=Departments&populate=Departments.Departments`);
            if (response.success) {
                if (response.data?.attributes?.Departments?.data.length == 0) {
                    infoNotify('No Department Found');
                    return;
                }
                setDepartmentOriginalList(response.data); // Store original department data
                setDepartmentFilteredList(response.data); // Initialize filtered list
            }
        } catch (error) {
            ErrorNotify('Error fetching departments');
        }
    }

    // Search organizations or departments based on search input
    function SearchEntity() {
        if (searchString.trim() === '') {
            // If search input is empty, reset the filtered list to the original list
            if (isOrganization.current) {
                setOrganizationFilteredList(organizationOriginalList);
            } else {
                setDepartmentFilteredList(departmentOriginalList);
            }
            return;
        }

        // Filter organizations or departments based on the search input
        if (isOrganization.current) {
            const filteredOrganizations = organizationOriginalList.filter((org) =>
                org.attributes?.Organisation_Name?.toLowerCase().includes(searchString.toLowerCase()) || org.attributes?.Department_Name?.toLowerCase().includes(searchString.toLowerCase())
            );
            setOrganizationFilteredList(filteredOrganizations); // Update the filtered state
        } else {
            const filteredDepartments = JSON.parse(JSON.stringify(departmentOriginalList)); // Create a shallow copy

            // Filter the departments within the original object
            const departmentData = filteredDepartments.attributes.Departments.data;

            // Filter the departments based on the search string
            filteredDepartments.attributes.Departments.data = departmentData.filter((department) =>
                department.attributes.Department_Name.toLowerCase().includes(searchString.toLowerCase())
            );

            // Only update the state if there are any filtered departments
            if (filteredDepartments.attributes.Departments.data.length > 0) {
                setDepartmentFilteredList(filteredDepartments); // Update the filtered state
            } else {
                setDepartmentFilteredList({}); // Reset if no departments match
            }
        }
    }

    // Updating 2 State of Organization Child Department
    function updateorg(info) {
        setOrganizationFilteredList(info);
        setOrganizationOriginalList(info);
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="content pb-0">
                    <div className="row">
                        <div className="col-sm-12 content_wrap_style">
                            <div className="content_wrap_comman">
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="breadcrumb_box">
                                                <Breadcrumb>
                                                    <Breadcrumb.Item as="li" href="/">
                                                        <img
                                                            src={
                                                                imagePath('/images/home_icon_pagig.svg')
                                                            }
                                                            alt=""
                                                        />
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item as="li" active aria-current="page">
                                                        Manage Billing
                                                    </Breadcrumb.Item>
                                                </Breadcrumb>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page_title_box">
                                        <h3 className="page_title">
                                            <span className='pe-3'><button className='btn btn_transparent'>
                                                <img onClick={() => { navigate(`/o/${orgStrId}/setup`) }} src='/images/back_arrow.svg' alt='back button' />
                                            </button></span> Manage Billing
                                        </h3>
                                    </div>
                                </div>
                                {/* search */}
                                <div className='d-flex justify-content-end'>
                                    <div className="useronboard_search ms-3">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder={isOrganization.current ? "Search Organisation" : "Search Department"}
                                            value={searchString}
                                            onChange={(e) => setSearchString(e.target.value)}
                                        />
                                        <button className="diy_emp_search_btn" onClick={SearchEntity}>
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>

                                {/* Table for Displaying Filtered Results */}
                                <div className='table_style_comman mt-3'>
                                    <div className='table-responsive'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sl. no</th>
                                                    <th>Name</th>
                                                    <th>Work Email</th>
                                                    <th>URL</th>
                                                    <th>Industry</th>
                                                    <th>Address</th>
                                                    <th>POC Name</th>
                                                    <th>POC Contact</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Mapping organizations or departments based on the current view */}
                                                {isOrganization.current ? (
                                                    organizationFilteredList.map((org, i) => (
                                                        <tr key={`OrganizationList ${i + 1}`}>
                                                            <td>{i + 1}</td>
                                                            <td className='link_underline' onClick={() => {
                                                                org?.attributes?.Departments?.data?.length == 0 ? infoNotify('No Department Found') : org?.attributes?.Departments?.data?.length > 0 ? updateorg(org?.attributes?.Departments?.data) : fetchDepartmentList(org?.id)
                                                            }}>
                                                                {org?.attributes?.Organisation_Name ? org?.attributes?.Organisation_Name : org.attributes?.Department_Name ? org.attributes?.Department_Name : 'Not Found'}
                                                            </td>
                                                            <td>{org?.attributes?.Work_Email ? org?.attributes?.Work_Email : 'Email not added'}</td>
                                                            <td>{org?.attributes?.Url ? org?.attributes?.Url : 'URL not added'}</td>
                                                            <td>{org?.attributes?.Industry ? org?.attributes?.Industry : 'Industry not added'}</td>
                                                            <td>{org?.attributes.Address_Line_1 ? org?.attributes?.Address_Line_1 : 'Address not added'}</td>
                                                            <td>{org.attributes?.Omind_POC_Name ? org.attributes?.Omind_POC_Name : 'Name not added'}</td>
                                                            <td>{org.attributes?.Omind_POC_Contact ? org.attributes?.Omind_POC_Contact : 'Contact not added'}</td>
                                                            {(org.attributes.Org_str_id || org.attributes?.Departments?.data?.length == 0 || !org.attributes?.Departments) && (
                                                                <td>
                                                                    <Link to={`/o/${orgStrId}/setup/billing-details`} className='link_text fw_600'>View</Link>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                ) : (
                                                    departmentFilteredList?.attributes?.Departments?.data?.map((dept, i) => (
                                                        <tr key={`DepartmentList ${i + 1}`}>
                                                            <td>{i + 1}</td>
                                                            <td className='link_underline' onClick={() => fetchDepartmentList(dept?.id)}>
                                                                {dept?.attributes?.Department_Name || 'Department Not Found'}
                                                            </td>
                                                            <td>{dept?.attributes?.Work_Email ? dept?.attributes?.Work_Email : 'Email not added'}</td>
                                                            <td>{dept?.attributes?.Url ? dept?.attributes?.Url : 'URL not added'}</td>
                                                            <td>{dept?.attributes?.Industry ? dept?.attributes?.Industry : 'Industry not added'}</td>
                                                            <td>{dept?.attributes.Address_Line_1 ? dept?.attributes?.Address_Line_1 : 'Address not added'}</td>
                                                            <td>{dept?.attributes?.Omind_POC_Name ? dept?.attributes?.Omind_POC_Name : 'Name not added'}</td>
                                                            <td>{dept?.attributes?.Omind_POC_Contact ? dept?.attributes?.Omind_POC_Contact : 'Contact not added'}</td>
                                                            {(!dept.attributes?.Departments?.data || dept.attributes?.Departments?.data?.length === 0) && (
                                                                <td>
                                                                    <Link to={`/o/${orgStrId}/setup/billing-details`} className='link_text fw_600'>View</Link>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageBilling;